<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :tabs="tabs" title="Discover" subtitle="Find opponents and events that match your particular interests." />

	<app-content-body :is-call="true" v-if="!collection.length">

		<app-content-call v-if="!hasInterests" icon="matchmaking" title="What games do you want to play?" text="Add your interests, as general or specific as you want, so we can tailor your suggestions." button="Add interests" v-on:click="onAddClick" />

		<app-content-call v-if="!collection.length && hasInterests" icon="interests" title="No suggestions currently" text="Suggestions are events submitted by other attendees that you might be interested in. You could try adding more interests or broadening existing interests to generate more suggestions." button="My interests" v-on:click="onInterestsClick" />

	</app-content-body>

	<app-events-filter :filter="filter" :references="references" v-if="collection.length" />

	<app-events v-if="collection.length" v-on:hide="load" :loading="is.loading && is.initialised" :filter="filter" :collection="collection" :columns="columns" :pagination="pagination" :total="total" placeholder="Sorry, no events found." />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/schedule/discover/suggestions',
			live: 'convention/schedule/discover/suggestions',
			filterTemplate: 'events',
			references: {
				times: [],
				days: [],
				lobbies: []
			},
			columns: {
				game: 200,
				seats: 100,
				date: 200,
				experience: 100,
				duration: 100,
				location: 'auto',
				actions: 72
			}
		}

	},

	computed: {

		hasInterests: function() {

			return this.$store.getters['session/count/schedule/interests']

		},

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Discover', text: 'Suggestions' },
				{ name: 'Convention.Schedule.Discover.Matchmaker', text: 'Matchmaker' },
				{ name: 'Convention.Schedule.Discover.Proposals', text: 'Proposals' },
				{ name: 'Convention.Schedule.Discover.Interests', text: 'Interests' }
			]

		}

	},

	methods: {

		onInterestsClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interests'
			})

		},

		onAddClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interest',
				params: {
					id: 'new'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>